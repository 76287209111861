@use 'sass:map';

@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/typography';

.header {
  padding-bottom: 1.75rem;
  border-bottom: 1px solid $border-colour;
  margin-bottom: 1.5rem;
}

.order-title {
  @extend .title-font;

  margin-bottom: 1.25rem;

  a {
    color: $body-color;
    text-decoration: none;
  }
}

.error {
  @extend .body-font;
}

.confidential {
  color: $warning;
}

.panels-grid {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.panels-row {
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
}

.order-details {
  flex-grow: 1;
}

.summary-of-charges {
  width: 17.375rem;
}

.additional-details {
}

@container ma-page (width < #{map.get($grid-breakpoints, "md")}) {
  .panels-row {
    flex-direction: column;
  }

  .summary-of-charges {
    width: auto;
  }
}
