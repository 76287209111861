.form {
  :global {
    .valid-feedback,
    .invalid-feedback {
      display: block;
    }
  }

  .note-feedback {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}
