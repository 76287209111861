@import '../../../common/assets/scss/variables';

.order-request-spend-table {
  border-radius: $border-radius-small;
  overflow: hidden;

  table {
    margin: 0;
    background-color: $white;
    border-color: $base-colour-light;
    vertical-align: middle;
  }

  thead {
    height: 2.5rem;
    tr {
      border-top: none;
    }
    th {
      background-color: $secondary-colour-light-blue;
      color: $base-colour-dark-grey;
      white-space: nowrap;
      border: none;
      font-weight: normal;

      &.quantity,
      &.price,
      &.subtotal,
      &.total {
        text-align: right;
      }

      &.actions {
        text-align: center;
      }
    }
  }

  tbody {
    tr {
      height: 2.5rem;
    }
    td {
      border: none;

      &.quantity,
      &.price,
      &.subtotal {
        text-align: right;
      }

      &.price,
      &.subtotal {
        text-wrap: nowrap;
      }

      &.actions {
        width: 4.5rem;

        div {
          display: flex;
          justify-content: space-between;
          width: 2.5rem;
          margin: auto;
        }
        button {
          min-width: 1rem;
          min-height: 1rem;

          svg {
            vertical-align: top;
          }

          &.remove {
            color: $base-colour-dark-grey;
          }
        }
      }
    }
  }

  tfoot {
    height: 2.5rem;
    tr {
      border-bottom: none;
    }
    td {
      border: none;
      background-color: $secondary-colour-light-blue;
      font-weight: 700;

      &.items,
      &.total {
        text-align: right;
      }
    }
  }
}
