@use 'sass:map';

@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/ma-controls';
@import 'SpendCategoriesTree.module.scss';

.content {
  margin-top: 1.25rem;
  padding-top: 1.25rem;
  border-top: 1px solid $border-colour;
}

.group-select {
  min-width: 10rem;
  width: 15%;
}

.group {
  margin-top: 1.25rem;
}

.group-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 0.75rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid $border-colour;
}

.group-name {
  font-family: $plus-jakarta-sans;
  margin-bottom: 0;
  font-size: 1.125rem;
}

.group-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1.25rem;
}

.group-controls-wrapper {
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
}

.default-category {
  min-width: 20rem;
  width: 20rem;
}

.toggle-inactive {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-bottom: 0.625rem;

  label {
    margin: 0;
    margin-right: 0.625rem;
    user-select: none;
  }

  :global(.ma-checkbox) {
    margin-bottom: 0.25rem;
  }
}

.add-new-category {
  flex-grow: 0;

  :global(.btn) {
    min-height: $ma-controls-min-height;
    width: 6.25rem;
  }
}

.spend-categories {
  height: calc(100dvh - 29rem);
}

@container ma-page (width < #{map.get($grid-breakpoints, "md")}) {
  .group-controls {
    flex-direction: column;
    row-gap: 1rem;
  }

  .group-controls-wrapper {
    width: 100%;
    justify-content: space-between;
  }

  .default-category {
    width: auto;
    min-width: 0;
  }

  .toggle-inactive {
    label {
      text-wrap: nowrap;
    }
  }
}

.spend-category-form {
  :global(.invalid-feedback) {
    display: block;
  }
}
