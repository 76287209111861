@import '../../../common/assets/scss/variables';
@import '../../../common/assets/scss/ma-controls';

.basket-line-item {
  overflow: hidden;

  &.compact {
    .body {
      flex-direction: column;
      row-gap: 0.75rem;
    }

    .controls {
      max-width: 50%;
      align-self: flex-end;
    }
  }

  .footer {
    padding-top: 0.75rem;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.25rem;
  overflow-wrap: anywhere;
}

.remove {
  color: $subtle;

  &:global(.spinner-border) {
    min-width: 1.3125rem;
    min-height: 1.3125rem;
    width: 1.3125rem;
    height: 1.3125rem;
  }
}

.product-name {
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  padding-right: 0.5rem;

  > a {
    color: $primary-a;
    text-decoration: none;
  }
}

.body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.summary {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 0.75rem;
  overflow-wrap: anywhere;

  th {
    color: $subtle;
    font-weight: normal;
    text-align: end;
    white-space: nowrap;
    vertical-align: top;
  }

  td {
    padding-left: 1rem;
  }
}

.img-wrapper {
  height: 5.5rem;
  max-height: 5.5rem;
  width: 5.5rem;
  min-width: 5.5rem;
}

.img {
  object-fit: contain;
  width: inherit;
  height: inherit;
  border: 1px solid $base-colour-lightest;
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 10rem;
}

.quantity {
  @include ma-input;

  max-width: 5rem;
  margin-bottom: 0.75rem;
}

.subtotal {
  display: flex;
  justify-content: space-between;
  column-gap: 0.5rem;
  width: 100%;
}

.subtotal-label {
  color: $subtle;
}

.subtotal-value {
  font-weight: 600;
}

.stock-info {
  font-size: $body-font-size;
  margin-bottom: 1.25rem;
}
