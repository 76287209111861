@import '../../../common/assets/scss/variables';

.title {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.25rem;
  align-items: flex-end;

  h4 {
    font-size: 1.125rem;
    margin: 0;
    margin-right: 0.5rem;
  }

  a {
    display: inline-block;
    font-size: $body-font-size;
  }
}

.tooltip {
  color: $success;

  &:not(:last-child) {
    margin-right: 2rem;
  }
}

.tooltip-text {
  width: 14.5rem;
}

.alt-product {
  margin: 0 0.625rem;
}

.matched-on {
  font-family: $be-vietnam-pro;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0.625rem;
  min-height: 1.125rem;
}
